import { isMobile } from "lib/deviceHelper";
import create from "zustand";

const URL_API_GENERATE =
  process.env.NEXT_PUBLIC_GARDEN_API_ROOT + "/garden/generate";
const URL_API_GET = process.env.NEXT_PUBLIC_GARDEN_API_ROOT + "/garden/get/";

function GeneratorError(message = "") {
  this.name = "GeneratorError";
  this.message = message;
}
GeneratorError.prototype = Error.prototype;

function LoadingGardenError(message = "") {
  this.name = "LoadingGardenError";
  this.message = message;
}
LoadingGardenError.prototype = Error.prototype;

export type GardenSettings = {
  width: number;
  height: number;

  plant_r: number;
  turing_r: number;
  trapline_r: number;

  // aspect: false,
  database_region: number;
  exposure: number;
  light_requirements: number;
  soil_ph: number;
  soil_requirements: number;

  remove: number[];
};

export type NodeData = {
  pids: number[];
  mat: number;
};

type State = {
  settings: GardenSettings;
  previousSettings: GardenSettings;
  uuid: string;

  nodes: Record<string, NodeData>;

  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  isGardenLoaded: boolean;

  usePregenerated: boolean;
  setUsePregenerated: (boolean) => void;

  hoveredPlant: NodeData;
  hoveredPlantPos: { x: number; y: number };
  setHoveredPlant: (
    nodeData: NodeData,
    mousePos: { x: number; y: number }
  ) => void;

  getNodeIdAtCoord: (coords: { x: number; y: number }) => number;
  getNodeAtCoord: (coords: { x: number; y: number }) => NodeData;
  getCoordForNodeId: (nid: number) => { x: number; y: number };
  toggleRemoveNode: (nid: number) => void;
  removeNodes: (nids: number[]) => void;
  addNodes: (nids: number[]) => void;

  aggregateByPids: (
    nodes: Record<string, NodeData>
  ) => { pid: number; count: number }[];
  updateSettings: (
    newSettings: Partial<GardenSettings>,
    force?: boolean,
    checkGraph?: boolean
  ) => void;
  generate: (adminToken?: string) => Promise<boolean>;
  load: (uuid?: string) => Promise<void>;
  loadJson: (json: string) => void;
  getJson: () => string;
  restorePreviousSettings: () => void;
};

const isM = isMobile();

export const useGardenStore = create<State>((set, get) => ({
  settings: {
    width: isM ? 12 : 20,
    height: isM ? 12 : 20,

    plant_r: isM ? 0.2 : 0.3,
    turing_r: 0.4,
    trapline_r: 0.3,

    database_region: 1,
    exposure: 1,
    light_requirements: 1,
    soil_ph: 3,
    soil_requirements: 1,

    remove: [],
  },
  previousSettings: null,

  uuid: "",

  nodes: {},

  isLoading: false,
  isError: false,
  errorMessage: "",
  isGardenLoaded: false,

  usePregenerated: true,
  setUsePregenerated: (usePregenerated) =>
    set(() => ({ usePregenerated })),
  
  hoveredPlant: null,
  hoveredPlantPos: null,

  setHoveredPlant: (nodeData, mousePos) =>
    set(() => ({ hoveredPlant: nodeData, hoveredPlantPos: mousePos })),

  getNodeIdAtCoord: ({ x, y }) => {
    return x + y * get().settings.width;
  },

  getNodeAtCoord: ({ x, y }) => {
    return get().nodes[x + y * get().settings.width];
  },

  getCoordForNodeId: (nid: number) => {
    const width = get().settings.width;
    return { x: nid % width, y: Math.floor(nid / width) };
  },

  toggleRemoveNode: (nodeId) =>
    set((state) => {
      const { width, height, remove } = state.settings;
      const newRemove = remove.includes(nodeId)
        ? remove.filter((id) => id !== nodeId)
        : remove.concat(nodeId);

      // check if the new graph is connected
      // if it's not, the algo. won't work
      const graph = getGraph2dArray(width, height, newRemove);
      const components = findComponents(graph, width, height);
      if (components.count > 1) return;

      return { settings: { ...state.settings, remove: newRemove } };
    }),

  // used in private grid generator only
  // does not check if graph is connected
  removeNodes: (nodes) =>
    set((state) => {
      const maxNid = state.settings.width * state.settings.height;
      const removeNodes = [...state.settings.remove, ...nodes].filter(
        (id) => id >= 0 && id < maxNid
      );
      return {
        settings: { ...state.settings, remove: removeNodes },
      };
    }),

  // used in private grid generator only,
  // does not check if graph is connected
  addNodes: (nodes) =>
    set((state) => {
      const maxNid = state.settings.width * state.settings.height;
      const removeNodes = state.settings.remove
        .filter((n) => !nodes.includes(n))
        .filter((id) => id >= 0 && id < maxNid);
      return {
        settings: {
          ...state.settings,
          remove: removeNodes,
        },
      };
    }),

  aggregateByPids: (nodes) => {
    // const nodes = get().nodes;
    if (Object.keys(nodes).length === 0) return null;

    // create an array with all the pids in the garden, including matrix plants
    const pids = Object.values(nodes)
      .reduce((acc, { pids, mat }) => [...acc, ...pids, mat], [])
      .filter((pid) => pid !== -1);

    // count them
    const countPids: Record<string, { pid: number; count: number }> =
      pids.reduce((acc, pid) => {
        if (acc[pid]) {
          acc[pid].count++;
        } else {
          acc[pid] = { pid, count: 1 };
        }
        return acc;
      }, {});

    // sort them
    return Object.values(countPids).sort((a: any, b: any) => b.count - a.count);
  },

  updateSettings: (newSettings, force = false, checkGraph = true) =>
    set((state) => {
      const updatedSettings = { ...state.settings, ...newSettings };
      // if we change width  we need to update the remove array
      if (!force && Object.keys(newSettings).includes("width")) {
        const newWidth = newSettings.width;
        const oldWidth = state.settings.width;
        updatedSettings.remove = state.settings.remove
          .map((nodeId) => {
            const oldCoord = [nodeId % oldWidth, Math.floor(nodeId / oldWidth)];
            if (oldCoord[0] >= newWidth) return -1;
            const newId = oldCoord[0] + oldCoord[1] * newWidth;
            return newId;
          })
          .filter((nodeId) => nodeId != -1);
      }

      // check if graph is connected
      if (checkGraph) {
        const { width, height, remove } = updatedSettings;
        const graph = getGraph2dArray(width, height, remove);
        const components = findComponents(graph, width, height);
        // if not we erase the remove array - this should not be that common
        if (components.count > 1) updatedSettings.remove = [];
      }

      return { settings: updatedSettings };
    }),

  generate: async (adminToken?: string) => {
    set({ isLoading: true, isError: false, errorMessage: "" });
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      if (adminToken) {
        headers["Authorization"] = `Bearer ${adminToken}`;
      }

      // remove settings if value is false
      const filteredSettings = Object.entries(get().settings).reduce(
        (acc, [key, value]) => {
          if (value) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      const resp = await fetch(URL_API_GENERATE, {
        method: "POST", // or 'PUT'
        headers,
        body: JSON.stringify({
          ...filteredSettings,
          usePregenerated: get().usePregenerated,
        }),
      });
      const json = await resp.json();
      if (!resp.ok) {
        throw new GeneratorError(json.message);
      }
      set((state) => ({
        isLoading: false,
        isGardenLoaded: true,
        nodes: json.scheme,
        uuid: json.uuid,
        previousSettings: { ...state.settings },
      }));
      return true;
    } catch (error) {
      set({ isLoading: false, isError: true, errorMessage: error.message });
      throw new GeneratorError(error.message);
    }
  },

  load: async (uuid) => {
    set({ isLoading: true, isError: false, errorMessage: "" });
    try {
      const resp = await fetch(URL_API_GET + uuid);
      const data = await resp.json();
      if (!resp.ok || !data) throw new LoadingGardenError(data.message);
      setGardenStateFromObject(data, set);
    } catch (error) {
      set({ isLoading: false, isError: true, errorMessage: error.message });
      throw new LoadingGardenError(error.message);
    }
  },

  loadJson: (json) => {
    set({ isLoading: true, isError: false, errorMessage: "" });
    try {
      const data = JSON.parse(json);
      setGardenStateFromObject(data, set);
    } catch (error) {
      set({ isLoading: false, isError: true, errorMessage: error.message });
      throw new LoadingGardenError(error.message);
    }
  },

  getJson: () => {
    const uuid = get().uuid;
    const scheme = get().nodes;
    return JSON.stringify({
      uuid,
      scheme,
      ...get().settings,
    });
  },

  restorePreviousSettings: () =>
    set((state) => {
      if (state.previousSettings) {
        return { settings: { ...state.previousSettings } };
      }
    }),
}));

const setGardenStateFromObject = (data: any, set) => {
  const newSettings = {
    width: data.width,
    height: data.height,
    plant_r: data.plant_r,
    turing_r: data.turing_r,
    trapline_r: data.trapline_r,
    database_region: data.database_region,
    exposure: data.exposure,
    light_requirements: data.light_requirements,
    soil_ph: data.soil_ph,
    soil_requirements: data.soil_requirements,
    remove: data.remove,
  };
  set((state) => ({
    uuid: data.uuid,
    isLoading: false,
    isGardenLoaded: true,
    nodes: data.scheme,
    settings: {
      ...state.settings,
      ...newSettings,
    },
    previousSettings: {
      ...state.settings,
      ...newSettings,
    },
  }));
};

const getGraph2dArray = (width: number, height: number, remove: number[]) => {
  const graph2dArray: boolean[][] = [];
  for (let x = 0; x < width; x++) {
    graph2dArray[x] = [];
    for (let y = 0; y < height; y++) {
      graph2dArray[x][y] = !remove.includes(x + y * width);
    }
  }
  return graph2dArray;
};

const findComponents = (graph: boolean[][], width: number, height: number) => {
  let component = 0;
  var labels: number[][] = graph.map((x) => x.map(() => 0));

  const dfs = (x: number, y: number, current_label: number) => {
    if (!graph[x] || !graph[x][y]) return; // out of bounds
    if (labels[x][y] || !graph[x][y]) return; // already labeled or hole in the graph

    // mark the current cell
    labels[x][y] = current_label;

    // recursively mark the neighbors
    dfs(x + 1, y, current_label);
    dfs(x - 1, y, current_label);
    dfs(x, y + 1, current_label);
    dfs(x, y - 1, current_label);
  };

  for (let x = 0; x < width; ++x)
    for (let y = 0; y < height; ++y)
      if (!labels[x][y] && graph[x][y]) dfs(x, y, ++component);

  return { count: component, labels };
};
