import settings from "settings";

export const isSmallScreen = (): boolean => {
  return window.innerWidth < settings.mobileBreakpoint;
} 

export const isMobile = (): boolean => {
  if (typeof window === "undefined" || typeof window.matchMedia === "undefined")
    return undefined;
  return window.matchMedia(
    `(hover: none) and (pointer: coarse) and (max-width: ${settings.mobileBreakpoint}px)`
  ).matches;
}