import { UIViews } from "stores/stateStore";
import { MathUtils, Spherical, Vector3 } from "three";
const { degToRad } = MathUtils;

export const settings = {
  mobileBreakpoint: 600,
  squareSize: 0.5, // in meters
  maxWidth: 30, // in grid unit
  maxHeight: 30, // in grid unit
  minWidth: 5, // in grid unit
  minHeight: 5, // in grid unit
  maxPixels: 30 * 30,

  min_plant_r: 0.1,
  max_plant_r: 0.5,
  min_turing_r: 0.1,
  max_turing_r: 0.7,

  cameraControl: {
    dampening: 0.2,
    cameraOffset: new Vector3(0, -1, 0),
    clampSMin: new Spherical(5, degToRad(0), degToRad(-90)),
    clampSMax: new Spherical(40, degToRad(75), degToRad(0)),
    deltaSAmp: new Spherical(0.3, 0.02, 0.02),
    defaultSCamera: {
      [UIViews.EditGarden]: new Spherical(20, degToRad(45), 0),
      [UIViews.GeneratingGarden]: new Spherical(20, degToRad(45), 0),
      [UIViews.LoadingGarden]: new Spherical(20, degToRad(45), 0),
      [UIViews.ViewGarden3d]: new Spherical(15, degToRad(60), degToRad(-45)),
      [UIViews.ViewGardenTop]: new Spherical(15, 0, 0),
      [UIViews.ViewGardenDiagram]: new Spherical(20, 0, 0),
    },
  },
  cameraControlMobile: {
    dampening: 0.2,
    cameraOffset: new Vector3(0, -1, 0),
    clampSMin: new Spherical(5, degToRad(0), degToRad(-90)),
    clampSMax: new Spherical(40, degToRad(90), degToRad(0)),
    deltaSAmp: new Spherical(0.3, 0.2, 0.2),
    defaultSCamera: {
      [UIViews.EditGarden]: new Spherical(10, degToRad(0), 0),
      [UIViews.GeneratingGarden]: new Spherical(20, degToRad(45), 0),
      [UIViews.LoadingGarden]: new Spherical(20, degToRad(45), 0),
      [UIViews.ViewGarden3d]: new Spherical(15, degToRad(60), degToRad(-45)),
      [UIViews.ViewGardenTop]: new Spherical(15, 0, 0),
      [UIViews.ViewGardenDiagram]: new Spherical(20, 0, 0),
    },
  },

  delayBeforeTourPrompt: 6000,
};

export const settingsMobile = {
  ...settings,

  maxWidth: 20, // in grid unit
  maxHeight: 20, // in grid unit
  minWidth: 5, // in grid unit
  minHeight: 5, // in grid unit
  maxPixels: 20 * 20,

  min_plant_r: 0.1,
  max_plant_r: 0.3,
};

export const regions = [
  { key: 100, val: 1, label: 'Atlantic Europe', countries: [
    { key: 101, val: 1, label: 'United Kingdom' },
    { key: 102, val: 1, label: 'Ireland' },
    { key: 103, val: 1, label: 'Western Norway' },
    { key: 104, val: 1, label: 'Netherlands' },
    { key: 105, val: 1, label: 'Northern Belgium' },
    { key: 106, val: 1, label: 'Northern France' },
    { key: 107, val: 1, label: 'Western France' },
    { key: 108, val: 1, label: 'Southwestern France' },
    { key: 109, val: 1, label: 'Northern Spain' },
    { key: 110, val: 1, label: 'Northern Portugal' },
  ] },
  { key: 200, val: 2, label: 'Continental Europe', countries: [
    { key: 201, val: 2, label: 'Germany' },
    { key: 202, val: 2, label: 'Southern Belgium' },
    { key: 203, val: 2, label: 'Luxembourg' },
    { key: 204, val: 2, label: 'Northeastern France' },
    { key: 205, val: 2, label: 'Eastern France' },
    { key: 206, val: 2, label: 'Northeastern Italy' },
    { key: 207, val: 2, label: 'Austria' },
    { key: 208, val: 2, label: 'Czech Republic' },
    { key: 209, val: 2, label: 'Poland' },
    { key: 210, val: 2, label: 'Denmark' },
  ] },
];

export default settings;